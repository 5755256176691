<template>      
	<form @submit.prevent="checkForm" class="mb-4">
		<template>
			<template v-if="ready == false">
				<LoadingSpinner class="col-12" />
			</template>
			<template v-else>				
				<div v-for="(invoice, key) in facture_avoir" :key="key" class="row">
					<div class="col-12">
						<label>{{ invoice.invoice_num }} // {{ invoice.tiers.tiers_rs }} - {{ invoice.author.tiers_rs }}</label>
						<b-table striped :items="ligne_avoir['F' + invoice.invoice_id]" :fields="columns">
							<template v-slot:cell(invoicedetails_label)="data">
								{{ data.item.invoicedetails_label }}
								<input type="text" class="form-control" :placeholder="$t('invoice.commentaire')" v-model="data.item.invoicedetails_description">
							</template>
							<template v-slot:cell(invoicedetails_htunit)="data">
								<div class="custom-control">
									<b-form-input 
										v-if="data.item.invoicedetails_pension_qte"
										type="text" 
										v-model="data.item.invoicedetails_htunit_before_discount"
										v-on:change="calcul_ht_pension(invoice.invoice_id, data.item.invoicedetails_id, data.item.invoicedetails_htunit_before_discount, data.item.invoicedetails_pension_qte, data.item.invoicedetails_tiershorsepartpart, data.item.invoicedetails_tiershorseparttype)"
										>
									</b-form-input>
									<b-form-input 
										v-else
										type="text" 
										v-model="data.item.invoicedetails_htunit_before_discount"
										v-on:change="calcul_ht(invoice.invoice_id, data.item.invoicedetails_id, data.item.invoicedetails_htunit_before_discount, data.item.invoicedetails_qte, data.item.invoicedetails_tiershorsepartpart, data.item.invoicedetails_tiershorseparttype)"
										>
									</b-form-input>
								</div>
							</template>
							<template v-slot:cell(invoicedetails_discount_value)="data">
								<span v-if="data.item.invoicedetails_discount_type">{{ data.item.invoicedetails_discount_value / 100 }} 
									<span v-if="data.item.invoicedetails_discount_type == 2">€</span>
									<span v-else>%</span>
								</span>
							</template>
							<template v-slot:cell(invoicedetails_tiershorsepartpart)="data">
								<span>{{ data.item.invoicedetails_tiershorsepartpart }}%</span>
							</template>
							<template v-slot:cell(invoicedetails_qte)="data">
								<div class="custom-control">
									<b-form-input
										v-if="data.item.invoicedetails_pension_qte"
										type="text" 
										v-model="data.item.invoicedetails_pension_qte"
										v-on:change="calcul_ht_pension(invoice.invoice_id, data.item.invoicedetails_id, data.item.invoicedetails_htunit_before_discount, data.item.invoicedetails_pension_qte, data.item.invoicedetails_tiershorsepartpart, data.item.invoicedetails_tiershorseparttype)"
										>
									</b-form-input>

									<b-form-input
										v-else 
										type="text" 
										v-model="data.item.invoicedetails_qte"
										v-on:change="calcul_ht(invoice.invoice_id, data.item.invoicedetails_id, data.item.invoicedetails_htunit_before_discount, data.item.invoicedetails_qte, data.item.invoicedetails_tiershorsepartpart, data.item.invoicedetails_tiershorseparttype)"
										>
									</b-form-input>
								</div>
							</template>
							<template v-slot:cell(invoicedetails_ht)="data">
								<span class="d-block" :ref="data.item.invoicedetails_id">{{ ht_formatted[data.item.invoicedetails_id] }}</span>
								<small v-if="`${data.item.invoicedetails_ht}`.replace(',', '.') > data.item.max_ht" class="text-danger">
									{{ $t('invoice.avoir_ht_max', { max_ht: data.item.max_ht }) }}
									</small>
							</template>
						</b-table>
					</div>
				</div>

				<div class="row ">
					<div class="col d-flex">
						<span class="font-weight-bold ml-auto">{{ $t('invoice.montant_ht') }} : {{ total_ht }}</span>
					</div>
				</div>
				<div class="form-group">
					<div class="row mx-0">
						<label for="date">{{ $t('invoice.definir_date_facturation') }}</label>
						<small v-if="last_invoice_date" class="mt-1 ml-2">{{ $t('invoice.date_derniere_facturation') }}: <a href="#" style="color:red;" @click="assignDate(last_invoice_date.invoice_date)">{{ last_invoice_date.formated_date }}</a></small>
					</div>
					<e-datepicker v-model="date" :class="{ 'is-invalid': dateFutur }"></e-datepicker>
					<small class="form-text text-muted">{{ $t('invoice.definir_date_facturation_text') }}</small>
					<ErrorAlert v-if="dateFutur" messageI18n="invoice.date_futur_avoir" />
					<ErrorAlert v-if="!invoiceable" messageI18n="invoice.avoir_pension_baf" />
				</div>
			</template>

			<b-modal ref="confirmation_first_invoice" hide-footer>
				<template v-slot:modal-title>
					{{ $t("invoice.title_first_invoice_of_month") }}
				</template>
				<div class="row justify-content-around">
					<div class="col">
						{{ $t("invoice.first_invoice_of_month") }}
						
						<b-button class="mt-3 rounded-pill" block @click.prevent="submit" variant="primary"><font-awesome-icon v-if="processing_check" :icon="['fas', 'spinner']" pulse /> {{ $t("global.continuer") }}</b-button>
						<b-button class="mt-3 rounded-pill" block @click.prevent="closeFirstInvoiceModal" variant="primary">{{ $t("global.annuler") }}</b-button>
					</div>
				</div>
			</b-modal>
		</template>
	</form>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import TableAction from "@/mixins/TableAction.js";
	import Navigation from "@/mixins/Navigation.js";
	import Config from "@/mixins/Config.js";
	import Invoice from "@/mixins/Invoice.js";
    import Tiers from "@/mixins/Tiers.js";
    import Payment from "@/mixins/Payment.js";
    import Contract from "@/mixins/Contract.js";
	import Tools from '@/mixins/Tools.js'
	import { EventBus } from 'EventBus';
	import Common from '@/assets/js/common.js'
	import _uniq from 'lodash/uniq'
	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'

	export default {
		name: "AddAvoir",
		mixins: [Config, TableAction, Navigation, Invoice, Tiers, Payment, Contract, Tools],
		props: ['facture_avoir', 'only_on_baf', 'invoiceable'],
		data () {
			return {
				processing: false,
				last_invoice_date: {},
				ready: false,
				avoir_method: '1',
				fields: ['horse_nom', 'invoicedetails_label','invoicedetails_htunit', 'invoicedetails_discount_value', 'invoicedetails_qte', 'invoicedetails_tiershorsepartpart', 'vat_label', 'invoicedetails_ht'],
				columns: [],
				ligne_avoir: {},
				date: new Date(),
				send_ligne: [],
				ligne_liaison: {},
				ht: {},
				ht_formatted: {},
				htunit: {},
				vat_valeur: {},
				vat: [],
				label_libre: null,
				ht_libre: null,
				selected_vat: null,
				ttc_libre: 0,
				total_ht: 0,
				invoices_lignes: [],
				authors: [],
				processing_check: false
			}
		},
		computed: {
			avoirTotalTtc() {
				let htPrices = Object.values(this.ht)
				let vatTab = Object.values(this.vat_valeur)
				let total = 0
				if(this.vat.length > 0)
				{
					for (let i = 0; i < htPrices.length; i++) {
						const index_vat = this.vat.filter(tab => {
							return tab.vat_id == vatTab[i]
						})[0]
						const vat_multiplier = 1 + index_vat.vat_value
						total += Common.truncateFloat(htPrices[i] * vat_multiplier)
						
					}
				}
				return Common.truncateFloat(total)
			},
			invoicesTotalHt() {
				let solde = 0
				this.facture_avoir.forEach(facture => {
					solde += facture.invoice_ht.toFloat()
				})
				return solde
			},
			invoicesBalanceTtc() {
				let solde = 0
				this.facture_avoir.forEach(facture => {
					solde += facture.invoice_balance.toFloat()
				})
				return solde
			},
			invoicesTotalTtc() {
				let solde = 0
				this.facture_avoir.forEach(facture => {
					solde += facture.invoice_ttc.toFloat()
				})
				return solde
			},
			isCreditMoreThanInvoices() {
				return this.avoirTotalTtc > this.invoicesBalanceTtc
			},
			dateFutur() {
				return this.date > new Date()
			}
		},
		watch: {

		},
		mounted() {
			this.init_component()
			this.$emit('update:ready', true)
			this.$emit('update:invoiceable', true)
		},
		methods: {
			async init_component() {
				await this.initInvoiceDetail();
				await this.initVat();
				await this.getLastInvoiceDate()

				this.fields.forEach(column => {
					this.columns.push({
						label : this.getTrad('invoice.'+column),
						key : column,
					})
				})
			},

			async initInvoiceDetail(){
				this.processing = false
				this.facture_avoir.forEach((facture) => {
					this.getCreditInvoice(facture.invoice_id).then(resCredit => {
						this.getInvoiceDetail(facture.invoice_id)
						.then(async res => {
							await Common.asyncForEach(
								res,
								async (ligne, index) => {

									this.authors.push(ligne.invoicedetails_author)
									let max_qte = parseFloat(ligne.invoicedetails_qte)
									let max_ht = this.parsePotentiallyGroupedFloat(ligne.invoicedetails_ht)
									if(Object.values(resCredit).length)
									{
										for (let i = 0; i < Object.values(resCredit).length; i++) {
											const filtredLine = Object.values(resCredit)[i].lignes.filter(ligneCredit => {
												return ligneCredit.invoicedetails_credit_ligne == ligne.invoicedetails_id
											})[0]
											
											//Si j'ai deja une facture d'avoir sur cette facture, il faut que je calcule la qte max que j'ai le droit pour faire mon nouvel avoir
											if(filtredLine != undefined) 
											{
												filtredLine.invoicedetails_qte = filtredLine.invoicedetails_qte * 1
												max_ht = (this.parsePotentiallyGroupedFloat(max_ht) + filtredLine.invoicedetails_ht).toFixed(2)
											}
										}

										max_qte = 1
										max_ht = Common.truncateFloat(max_ht)
										ligne.invoicedetails_htunit = max_ht
									}

									max_ht = max_ht / 100
							
									ligne.invoicedetails_htunit = ligne.invoicedetails_htunit / 100
									ligne.invoicedetails_htunit_before_discount = ligne.invoicedetails_ht_initial ? ligne.invoicedetails_ht_initial / 100 : ligne.invoicedetails_htunit_before_discount / 100
									ligne.invoicedetails_ht = max_ht
									ligne.invoicedetails_qte = max_qte
									ligne.max_ht = max_ht
									ligne.max_qte = max_qte
									ligne.model = facture.invoice_model

									this.htunit[ligne.invoicedetails_id] = ligne.invoicedetails_htunit

									ligne.invoicedetails_htunit = await this.priceFormat(ligne.invoicedetails_htunit, facture.author.tiers_currency)
									if(this.ligne_avoir["F" + facture.invoice_id] == undefined)
									{
										this.$set(this.ligne_avoir, "F" + facture.invoice_id, [])
									}

									if(!ligne.invoicedetails_articles) {
										return false
									}

									this.ligne_avoir["F" + facture.invoice_id].push(ligne)

									this.$set(this.ht, ligne.invoicedetails_id, ligne.invoicedetails_ht)

									this.vat_valeur[ligne.invoicedetails_id] = ligne.invoicedetails_vat

									this.ligne_liaison[ligne.invoicedetails_id] = index

									if(ligne.invoicedetails_horsepensionperiode && Date.parseTz(facture.invoice_date) < Date.parseTz('2024-04-20')) {
										this.$emit('update:invoiceable', false)
									}
								}
							)
							await this.recalcul_ht()
							this.ready = true;
							this.$emit('update:ready', true)
						})
					})

					
				})
			},

			async getLastInvoiceDate(){
                this.last_invoice_date = await this.getLastInvoiceDateByAuthor(this.facture_avoir[0].author.tiers_id)
				if(!this.last_invoice_date && !this.last_invoice_date.invoice_date){
					this.last_invoice_date.invoice_date = ""
				}
				this.last_invoice_date.invoice_date = new Date(this.last_invoice_date.invoice_date)
				// this.last_invoice_date.formated_date = this.last_invoice_date.invoice_date.toLocaleDateString(Vue.i18n.locale())
				this.last_invoice_date.formated_date = this.formatDate(this.last_invoice_date.invoice_date)
			},

			async initVat(){
                this.vat = await this.getVat()
				this.selected_vat = 3
			},

			assignDate(date) {
				this.date = date
			},

			async getInvoiceDetailsCurrency(invoicedetails_id) {
				invoicedetails_id = parseInt(invoicedetails_id)

				const facture_nums = Object.keys(this.ligne_avoir)

				// Pour chaque facture
				for (let i = 0; i < facture_nums.length; i++) {
					const facture_num = facture_nums[i]
					const lignes = this.ligne_avoir[facture_num]
					// Pour chaque ligne de cette facture
					for (let j = 0; j < lignes.length; j++) {
						const ligne = lignes[j]
						// Si la ligne est celle qu'on recherche
						if (ligne.invoicedetails_id === invoicedetails_id) {
							// On retourne la devise de la facture
							const facture_id = parseInt(facture_num.replace('F', ''))
							const facture = this.facture_avoir.find(f => f.invoice_id === facture_id)
							return facture.author.tiers_currency
						}
					}
				}
			},

			async createAvoirTotal(){

				let can_continue = true

				for (let facture_id in this.ligne_avoir)
				{
					for (let details in this.ligne_avoir[facture_id])
					{
						if(this.ligne_avoir[facture_id][details].invoicedetails_ht > this.ligne_avoir[facture_id][details].max_ht)
						{
							can_continue = false
						}
					}
				}

				if(can_continue)
				{
					let authors = _uniq(this.authors).join(",")

					let checking_date = await this.checkCreditDate(this.date.toDateInputValue(), authors)

					if(checking_date == null)
					{
						return false
					}

					await Common.asyncForEachObject(this.ligne_avoir, async (facture, i) => {
						this.send_ligne = []

						let contract_ids = []						

						await Common.asyncForEach(facture, (ligne, index) => {

							if(ligne.invoicedetails_qte > 0 && (!ligne.invoicedetails_pension_qte || ligne.invoicedetails_pension_qte.split('/')[0] !== '0')) {
								this.send_ligne.push({
									'author_id' 		  : ligne.invoicedetails_author,
									'tiers_id' 		  	  : ligne.invoicedetails_tiers,
									'htunit'		 	  : - this.parsePotentiallyGroupedFloat(ligne.invoicedetails_htunit_before_discount),
									'ht_initial'		  : - this.parsePotentiallyGroupedFloat(ligne.invoicedetails_htunit_before_discount) * 100,
									'qte' 				  : ligne.invoicedetails_qte,
									'vat' 				  : ligne.invoicedetails_vat,
									'label'			  	  : ligne.invoicedetails_label,
									'description'	  	  : ligne.invoicedetails_description,
									'horse_id'			  : ligne.invoicedetails_horse,
									'articles_id' 		  : ligne.invoicedetails_articles,
									'tiershorseparttype'  : ligne.invoicedetails_tiershorseparttype,
									'tiershorsepartpart'  : ligne.invoicedetails_tiershorsepartpart,
									'horsepensionperiode' : ligne.invoicedetails_horsepensionperiode,
									'avenantarticles_id'  : ligne.invoicedetails_avenantarticles,
									'contract_id'  		  : ligne.invoicedetails_contract,
									'actes_id'  		  : ligne.invoicedetails_actes,
									'credit_ligne'  	  : ligne.invoicedetails_id,
									'vat_code'			  : ligne.invoicedetails_codevat,
									'export_vat'		  : ligne.invoicedetails_exportvat,
									'model'		  		  : ligne.model,
									'discount_value'	  : ligne.invoicedetails_discount_type == 2 ? (-ligne.invoicedetails_discount_value/100) : ligne.invoicedetails_discount_value/100,
									'discount_type'		  : ligne.invoicedetails_discount_type,
									'avoir' 			  : true,
									'pension_qte'		  : ligne.invoicedetails_pension_qte
								})

								if(ligne.invoicedetails_contract && !contract_ids.includes(ligne.invoicedetails_contract)) {
									contract_ids.push(ligne.invoicedetails_contract)
								}
							}
						})

						if(this.send_ligne.length > 0){
							await this.createInvoiceDetails({details: this.send_ligne})
								.then(async res => {
									let id = "";
									res.retour.forEach(detail => {
										id += detail.invoicedetails_id+","
									})
									id = id.slice(0, -1);

									if(this.only_on_baf !== true)
									{
										await this.createFactureAvoir(id, this.send_ligne[0]['author_id'], this.send_ligne[0]['tiers_id'], i, this.send_ligne[0]["model"])
									}
								})
						}

	                    await this.$sync.force(true)
	                    ContractCleaner.inst().onMutation(contract_ids, ['articles'])
	                    this.updateContract(contract_ids)
					})
				}
				else{
					return false
				}
			},

			async createFactureAvoir(id, author, tiers, facture_id, model){
				

				let config_duedate = await this.getConfigDuedate([tiers])
				let current = this.calculNextDateDuedate(config_duedate[tiers], this.date.toDateInputValue())

				let obj = {
					date: this.date.toDateInputValue(),
					invoices: [
						{
							"author_id" : author,
							"tiers_id": tiers,
							"model": {
								"model_id": model
							},
							"duedates": [{
								'date': current,
							}]
						}
					],
					invoicedetails_ids: id
				}


				let invoice_balance = await this.getBalanceInvoices(facture_id.replace('F', ''))
				invoice_balance = invoice_balance.retour.invoice_balance

				const res = await this.createInvoice(obj)
				let promises = []

				let amount = 0
				let invoice_num = ''
				res.retour.forEach((r) => {
					promises.push(
						this.createCredit({
							invoice_ids: [facture_id.replace('F', '')],
							credit_id: r.invoice_id,
						})
					)
					invoice_num = r.invoice_num

					if(invoice_balance != 0)
					{
						let amount_payment = invoice_balance * -1
						if(invoice_balance > (r.invoice_ttc * -1)) {
							amount_payment = r.invoice_ttc
						}

						amount += amount_payment
						promises.push(
							this.createPayment({
								invoice_ids: [r.invoice_id],
								payment_amount: amount_payment,
								payment_date: current,
								payment_method: 8,
								tiers_id: tiers,
							})
						)
					}
				})

				if(invoice_balance != 0)
				{
					promises.push(
						this.createPayment({
							invoice_ids: [facture_id.replace('F', '')],
							payment_amount: amount * -1, // je repasse l'amount en positif
							payment_date: current,
							payment_method: 9,
							tiers_id: tiers,
							payment_comment: invoice_num
						})
					)
				}

				await Promise.all(promises)
			},

			async calcul_ht(facture_id, id, htunit, qte, part, part_type){
				let current_qte = qte.toString()
				let current_htunit = htunit.toString()
				if(qte.toString().indexOf(",") > -1){
					current_qte = qte.toString().substring(0, qte.toString().indexOf(",") + 3)
				}
				else if(qte.toString().indexOf(".") > -1){
					current_qte = qte.toString().substring(0, qte.toString().indexOf(".") + 3)
				}

				if(htunit.toString().indexOf(",") > -1){
					current_htunit = this.parsePotentiallyGroupedFloat(htunit)
				}
				else if(htunit.toString().indexOf(".") > -1){
					current_htunit = this.parsePotentiallyGroupedFloat(htunit)
				}

				let ht = this.parsePotentiallyGroupedFloat((current_qte.replace(",", ".") * current_htunit).toFixed(2))
				if(part_type !== 0) {
					ht = this.parsePotentiallyGroupedFloat((current_qte.replace(",", ".") * current_htunit * (part*0.01)).toFixed(2))
				}
				this.ht[id] = ht
				this.$refs[id].innerText = this.ht[id]

				this.ligne_avoir["F" + facture_id][this.ligne_liaison[id]].invoicedetails_qte = current_qte.replace(",", ".")
				this.ligne_avoir["F" + facture_id][this.ligne_liaison[id]].invoicedetails_htunit = current_htunit
				this.ligne_avoir["F" + facture_id][this.ligne_liaison[id]].invoicedetails_ht = ht

				await this.recalcul_ht()
			},

			async calcul_ht_pension(facture_id, id, htunit, qte, part, part_type){
				let current_htunit = htunit.toString()

				if(htunit.toString().indexOf(",") > -1){
					current_htunit = this.parsePotentiallyGroupedFloat(htunit)
				}
				else if(htunit.toString().indexOf(".") > -1){
					current_htunit = this.parsePotentiallyGroupedFloat(htunit)
				}

				const pension_qte = qte.split('/')

				let ht = Common.truncateFloat(pension_qte[0] / pension_qte[1] * current_htunit)
				if(part_type !== 0) {
					ht = Common.truncateFloat(pension_qte[0] / pension_qte[1] * current_htunit * (part*0.01))
				}
				this.ht[id] = ht
				this.$refs[id].innerText = this.ht[id]

				this.ligne_avoir["F" + facture_id][this.ligne_liaison[id]].invoicedetails_pension_qte = qte
				this.ligne_avoir["F" + facture_id][this.ligne_liaison[id]].invoicedetails_htunit = current_htunit
				this.ligne_avoir["F" + facture_id][this.ligne_liaison[id]].invoicedetails_ht = ht

				await this.recalcul_ht()
			},

			async submit(){
				if(this.processing == false && !this.dateFutur)
				{
					this.processing = true;
					this.$emit('update:processing', true)
					const can_close = await this.createAvoirTotal()
					this.processing = false
					this.$emit('update:processing', false)
					if(can_close !== false)
					{
						EventBus.$emit('close_modal', true)
					}
					
				}
			},
			async recalcul_ht() {

				this.total_ht = 0

				await Common.asyncForEachObject(this.ht, async (elem, invoicedetails_id) => {
					const currency = await this.getInvoiceDetailsCurrency(invoicedetails_id)

					const price = await this.priceFormat(elem, currency, true)

					this.total_ht += elem
					this.$set(this.ht_formatted, invoicedetails_id, price)
				})
			},
			checkIfFirstInvoiceMonth: async function(only_baf = false) {
				this.processing_check = true
				this.$emit('update:processing', true)

				if(!only_baf) {
					let invoicedetails_ids = []
					await Common.asyncForEach(this.facture_avoir, async (invoice) => {
						const details = await this.getInvoiceDetail(invoice.invoice_id)
						details.forEach(detail => {
							invoicedetails_ids.push(detail.invoicedetails_id)
						})
					})

					const elems = await this.checkFirstInvoiceMonth(this.date.toDateInputValue(), invoicedetails_ids.join(', '))
					
					this.processing_check = false
					this.$emit('update:processing', false)
					if(elems.nb == 0) {
						this.$refs['confirmation_first_invoice'].show()
						return true
					}
				}
				await this.submit()
			},
			closeFirstInvoiceModal: async function(){
				this.$refs['confirmation_first_invoice'].hide()
			}
		},
		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert')
		}
	}
</script>